import React from 'react';
import {
  StyledCallToAction,
  StyledHeading,
  StyledImageTag,
  StyledParagraph,
} from '../../../sharedComponents';
import { Underline } from '../../innerComponents';
import cn from 'classnames';

export const ImageBottomDisplayStyle = ({
  section: { title, titleSize, text, image, imageWidth, link, alt },
  displayStyles,
  visualStyles,
}) => {
  const ctaMode = visualStyles?.elements?.callToAction?.mode || {};
  const {
    elements: { imageTag: { settings: { sm = 8, pos = '' } = {} } = {} },
  } = displayStyles;
  const pageWidth = imageWidth === 'PAGE';
  const [col, offset] = [sm, (12 - sm) / 2];

  const imageTagGridClassName = cn('col-xs-12', {
    [`col-sm-${col} col-sm-offset-${offset} ${pos}`]: !pageWidth,
  });

  const paragraphGridClassName = cn('col-xs-12', {
    'col-xs-12 col-sm-6 col-sm-offset-3': !pageWidth,
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-sm-offset-3">
          {title && (
            <StyledHeading
              content={title}
              as={titleSize}
              displayStyles={displayStyles}
            />
          )}
        </div>
        <div className="col-xs-12">
          {title && !pageWidth && (
            <Underline
              displayStyles={displayStyles}
              visualStyles={visualStyles}
            />
          )}
        </div>
        <div className={paragraphGridClassName}>
          {text && (
            <StyledParagraph content={text} displayStyles={displayStyles} />
          )}
        </div>
        <div className={imageTagGridClassName}>
          <StyledImageTag
            imageConfig={image}
            displayStyles={displayStyles}
            alt={alt}
            pageWidth={pageWidth}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {link?.type && (
            <StyledCallToAction
              ctaConfig={link}
              mode={ctaMode}
              displayStyles={displayStyles}
            />
          )}
        </div>
      </div>
    </div>
  );
};
