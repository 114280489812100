import React, { useContext } from 'react';
import classNames from 'classnames';
import {
  StyledCallToAction,
  StyledHeading,
  StyledImageTag,
  StyledParagraph,
} from '../../../sharedComponents';
import { Underline } from '../../innerComponents';
import { ThemeContext } from 'styled-components';

export const ImageRightDisplayStyle = (props) => {
  const {
    section: {
      title,
      linkPosition,
      titleSize,
      text,
      image,
      imageSize,
      imageWidth,
      link,
      alt,
    },
    displayStyles,
    visualStyles,
  } = props;
  const themeConfig = useContext(ThemeContext);
  const pageWidth = imageWidth === 'PAGE';
  const inline = linkPosition === 'INLINE';

  const isRTL = themeConfig.globalVariables.isRTL;
  const {
    elements: {
      imageTag: { settings: { sm = 6, md = 7, pos = 'last-xs' } = {} } = {},
    },
  } = displayStyles;
  const ctaMode = visualStyles?.elements?.callToAction?.mode || {};

  const imageRightRowClass = classNames('row', {
    reverse: isRTL,
  });

  const dynamicContentSizeClassName = classNames('col-xs-12', {
    [`col-sm-${12 - sm} col-md-${12 - md}`]: imageSize === 'STANDARD',
    'col-sm-6': imageSize === 'HALF',
    'col-sm-8': imageSize === 'THIRD',
    'col-sm-9': imageSize === 'QUARTER',
  });
  const dynamicImageSizeClassName = classNames('col-xs-12', pos, {
    [`col-sm-${sm} col-md-${md}`]: imageSize === 'STANDARD',
    'col-sm-6': imageSize === 'HALF',
    'col-sm-4': imageSize === 'THIRD',
    'col-sm-3': imageSize === 'QUARTER',
  });

  return (
    <div className="container">
      <div className={imageRightRowClass}>
        <div className={dynamicContentSizeClassName}>
          {title && (
            <StyledHeading
              content={title}
              as={titleSize}
              displayStyles={displayStyles}
            />
          )}
          {title && !pageWidth && (
            <Underline
              displayStyles={displayStyles}
              visualStyles={visualStyles}
            />
          )}
          {text && (
            <StyledParagraph content={text} displayStyles={displayStyles} />
          )}
          {inline && link?.type && (
            <StyledCallToAction
              ctaConfig={link}
              mode={ctaMode}
              displayStyles={displayStyles}
              testId="video-cta-inline"
            />
          )}
        </div>
        <div className={dynamicImageSizeClassName}>
          <StyledImageTag
            imageConfig={image}
            displayStyles={displayStyles}
            alt={alt}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 center-xs">
          {!inline && link?.type && (
            <StyledCallToAction
              ctaConfig={link}
              mode={ctaMode}
              displayStyles={displayStyles}
            />
          )}
        </div>
      </div>
    </div>
  );
};
